.header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);

  > div {
    display: flex;
    align-items: center;
    gap: var(--spacing-8);
  }
}
