.cardInfo {
  display: flex;
  align-items: center;
  gap: var(--spacing-8);
  background-color: var(--layer-2);
  border-radius: var(--radius-s);
  padding: 8px 12px;
}

.warning {
  background: #FFF0D6;
}

.danger {
  background: #FFDCDA;
}
