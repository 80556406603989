@import "/src/styles/mixins.scss";

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-16);
  z-index: 1;
  box-sizing: border-box;

  @include breakpoint(tablet) {
    margin-bottom: var(--spacing-64);
    border-radius: var(--radius-l);
  }
}

.title {
  display: flex;
  align-items: center;
  gap: var(--spacing-8);
}

.card {
  margin-top: var(--spacing-16);

  > div {
    padding: var(--spacing-16);
  }

  > section {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-16);
    border-top: 1px solid var(--background-primary);
  }

  @include breakpoint(tablet) {
    margin-top: var(--spacing-20);

    > div,
    section {
      padding: var(--spacing-20);
    }
  }
}
