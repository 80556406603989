:root {
  /* Spacing */
  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-6: 6px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-14: 14px;
  --spacing-16: 16px;
  --spacing-20: 20px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-40: 40px;
  --spacing-48: 48px;
  --spacing-56: 56px;
  --spacing-64: 64px;
  --spacing-80: 80px;
  --spacing-120: 120px;

  /* Radius */
  --radius-full: 100%;
  --radius-l: 24px;
  --radius-m: 16px;
  --radius-s: 8px;
  --radius-xs: 4px;

  /* Colors */
  --green-colour: #29845a;
}
