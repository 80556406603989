.icon {
  width: var(--icon-size);
  height: var(--icon-size);
  min-width: var(--icon-size);
  min-height: var(--icon-size);

  path[fill] {
    fill: var(--icon-intent);
  }

  path[stroke] {
    stroke: var(--icon-intent);
  }
}
