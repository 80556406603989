@import "/src/styles/mixins.scss";

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-16);
  z-index: 1;
  box-sizing: border-box;

  @include breakpoint(tablet) {
    margin-bottom: var(--spacing-64);
    border-radius: var(--radius-l);
  }

  button > p {
    font-size: 16px;

    @include breakpoint(tablet) {
      font-size: 18px;
    }
  }
}

.linksContainer {
  margin: var(--spacing-16) 0;

  > a > section {
    border-radius: 0;
    border-bottom: 1px solid var(--background-primary);
  }

  > a:first-of-type > section {
    border-top-left-radius: var(--radius-m);
    border-top-right-radius: var(--radius-m);
  }

  > a:last-of-type > section {
    border: none;
    border-bottom-left-radius: var(--radius-m);
    border-bottom-right-radius: var(--radius-m);
  }

  > section {
    border-radius: 0;
    border-bottom: 1px solid var(--background-primary);
  }

  > section:first-of-type {
    border-top-left-radius: var(--radius-m);
    border-top-right-radius: var(--radius-m);
  }

  > section:last-of-type {
    border: none;
    border-bottom-left-radius: var(--radius-m);
    border-bottom-right-radius: var(--radius-m);
  }
}

.faqBtn {
  > button {
    padding: var(--spacing-16);
    text-align: left;

    p {
      font-weight: 400;
    }

    @include breakpoint(tablet) {
      padding: var(--spacing-24);
    }
  }
}

.answer {
  padding: var(--spacing-16);
  padding-top: 0;

  @include breakpoint(tablet) {
    padding: var(--spacing-24);
    padding-top: 0;
  }
}
