$tablet: 768px;
$desktop: 1025px;

$breakpoints: (
  tablet: $tablet,
  desktop: $desktop,
);

@mixin breakpoint($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  } @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}

@mixin map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}

:export {
  tablet: $tablet;
  desktop: $desktop;
}

// Used in almost all pages
.content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-16);
  z-index: 1;
  box-sizing: border-box;

  @include breakpoint(tablet) {
    margin-bottom: var(--spacing-64);
    border-radius: var(--radius-l);
  }
}
