@import "/src/styles/mixins.scss";

.header {
  padding-bottom: var(--spacing-8);
  margin-bottom: var(--spacing-8);
  border-bottom: 1px solid var(--background-primary);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--spacing-8);

  @include breakpoint(tablet) {
    padding-bottom: var(--spacing-16);
    margin-bottom: var(--spacing-16);
  }
}

.details {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-8);
  }
}

.boldContent {
  * {
    font-weight: 500;
  }
}

.subtleColorContent {
  color: var(--static-content-subtle);
}

.btnContainer {
  margin-top: var(--spacing-24);

  > a > button > p {
    font-size: 16px;
  }
}

.paidTotalTooltip {
  display: flex;
  align-items: center;
  gap: var(--spacing-8);
}
