.loadingScreen {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
        margin-top: var(--spacing-32);
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--spacing-12);

        h1, h2 {
            color: var(--static-content-subtle);
            font-weight: normal;
            font-size: var(--spacing-24);
        }

        h1 {
            font-weight: 500;
        }
    }
}