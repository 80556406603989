@font-face {
  font-family: "Intrum Sans";
  src:
    url("../assets/fonts/intrum-sans/woff2/IntrumSans-Regular.woff2")
      format("woff2"),
    url("../assets/fonts/intrum-sans/woff/IntrumSans-Regular.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Intrum Sans";
  src:
    url("../assets/fonts/intrum-sans/woff2/IntrumSans-Bold.woff2")
      format("woff2"),
    url("../assets/fonts/intrum-sans/woff/IntrumSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Intrum Sans";
  src:
    url("../assets/fonts/intrum-sans/woff2/IntrumSans-BoldItalic.woff2")
      format("woff2"),
    url("../assets/fonts/intrum-sans/woff/IntrumSans-BoldItalic.woff")
      format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Intrum Sans";
  src:
    url("../assets/fonts/intrum-sans/woff2/IntrumSans-Medium.woff2")
      format("woff2"),
    url("../assets/fonts/intrum-sans/woff/IntrumSans-Medium.woff")
      format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Intrum Sans";
  src:
    url("../assets/fonts/intrum-sans/woff2/IntrumSans-MediumItalic.woff2")
      format("woff2"),
    url("../assets/fonts/intrum-sans/woff/IntrumSans-MediumItalic.woff")
      format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Intrum Sans";
  src:
    url("../assets/fonts/intrum-sans/woff2/IntrumSans-RegularItalic.woff2")
      format("woff2"),
    url("../assets/fonts/intrum-sans/woff/IntrumSans-RegularItalic.woff")
      format("woff");
  font-weight: normal;
  font-style: italic;
}
