.emptyHistoryContainer {
  height: 300px;
  padding: var(--spacing-24);
  display: flex;
  text-align: center;
  gap: var(--spacing-12);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--static-content-subtle);
}
