.loader {
    width: 100px;
    height: 100px;
    border: 10px solid #FFF;
    border-bottom-color: transparent !important;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    @media(max-width: 1023px) {
        & {
            width: 50px !important; 
            height: 50px !important;
            border-width: 5px !important;
        }
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
} 