.tabsWrapper {
  width: 100%;
}

.tabsContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: var(--spacing-24);
  position: relative;

  @media (orientation: portrait) and (max-width: 768px) {
    & {
      gap: 10px;
    }
  }
}

.tabsContent > div:not(.border) {
  flex: 1;
  display: grid;
  justify-items: center;
  align-items: center;
  padding: var(--spacing-8) 0;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;

  &:focus {
    outline-color: inherit;
  }
}

.tabItemWithTooltip {
  grid-template-columns: 1fr var(--spacing-16);
  padding-right: 5px !important;
}

.tabsContent.initial > div:not(.border):first-child {
  position: relative;
}

.tabsContent.initial > div:not(.border):first-child::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
  border-bottom-color: inherit;
}

.border {
  position: absolute;
  left: 0;
  top: 100%;
  border-bottom: 1.5px solid transparent;
  transition: left 0.2s ease-in-out;
}
