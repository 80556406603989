.radioButtonsWrapper {
    label {
        margin-top: var(--spacing-12);
        display: flex;
        align-items: center;
        gap: var(--spacing-12);
        cursor: pointer;

        input {
            -webkit-appearance: none;
            appearance: none;
            background-color: var(--static-content-emphasis-inverted);
            margin: 0;
            color: var(--primary-color);
            width: 19px;
            height: 19px;
            border: 1px solid var(--stroke-inverted);
            border-radius: 50%;
            display: grid;
            place-content: center;

            &::before {
                content: "";
                border-radius: 50%;
                transform: scale(0);
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em red;
            }

            &:checked {
                border-width: 6px;
            }

            &:checked::before {
                transform: scale(1);
            }
        }
    }
}