@import "/src/styles/mixins.scss";

.activityContainer {
  max-height: 400px;
  padding-right: 10px;
  overflow: auto;
  padding: var(--spacing-24);
}

.activityInnerContainer {
  position: relative;
  padding-left: var(--spacing-16);

  --card-padding: -16px;

  @include breakpoint(tablet) {
    --card-padding: -24px;
    padding-left: var(--spacing-24);
  }

  // The black line going through the circles
  &::before {
    position: absolute;
    content: "";
    height: calc(100% - var(--spacing-32));
    width: 1px;
    left: -0.3px;
    background-color: var(--primary-color);

    @include breakpoint(tablet) {
      height: calc(100% - var(--spacing-48));
    }
  }

  > div:first-of-type {
    margin: 0;

    &:only-child {
      &::before {
        position: absolute;
        content: "";
        width: 3px;
        height: 60px;
        background-color: white;
        left: calc(var(--card-padding) - 1px);
      }
    }

    &::after {
      position: absolute;
      content: "";
      width: 3px;
      height: 60px;
      background-color: white;
      left: calc(var(--card-padding) - 1px);
    }
  }

  > div:last-of-type {
    &::after {
      position: absolute;
      content: "";
      width: 2px;
      height: 56px;
      background-color: white;
      left: calc(var(--card-padding));
      bottom: 0;
    }
  }
}

.monthContainer {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-24);
  position: relative;

  > h4 {
    font-weight: 500;
    margin-bottom: var(--spacing-16);
  }

  @include breakpoint(tablet) {
    margin-top: var(--spacing-32);
  }
}

.activity {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--background-primary);
  padding-top: var(--spacing-8);
  padding-bottom: var(--spacing-12);
  position: relative;

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
  }

  &::before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--primary-color);
    top: var(--spacing-12);
    left: calc(var(--card-padding) - 4px);
    z-index: 2;
  }
}
