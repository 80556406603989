@import "/src/styles/mixins.scss";

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-24);
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  @media (max-width: 550px) {
    & {
      position: static;
    }
  }

  @include breakpoint(tablet) {
    padding: var(--spacing-32);
  }

  @media (max-width: 1024px) {
    & {
      padding: var(--spacing-24);
    }
  }

  &[data-appearance="dark"] {
    background: transparent;

    .leftContainer {
      p {
        color: var(--content-emphasis);
      }
    }
  }
}

.dropdowns {
  display: flex;
  align-items: center;
  gap: var(--spacing-80);

  @media (orientation: portrait) and (max-width: 500px) {
    & {
      gap: var(--spacing-20);
    }
  }
}

.leftContainer {
  display: flex;
  align-items: center;
  cursor: pointer;

  p {
    font-size: 16px;

    @include breakpoint(tablet) {
      font-size: 18px;
    }
  }

  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    margin-right: var(--spacing-12);
    border-radius: var(--radius-s);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.headerImage {
  width: 100%;

  picture {
    display: flex;
    position: relative;
    padding-bottom: 56.25%;

    @media (max-width: 1430px) and (orientation: landscape) {
      & {
        padding-bottom: 42.86%;
      }
    }

    @media (min-width: 1440px) {
      & {
        padding-bottom: 42.86%;
      }
    }

    img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.menuContainer {
  position: relative;

  .hamburger {
    width: 24px;
    height: 24px;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1.5px;
      left: 0;
      color: var(--primary-color);
      background-color: currentColor;
      transition: all 200ms ease-in-out;
    }

    &::before {
      top: 6px;
    }
    &::after {
      bottom: 6px;
    }
  }

  .dark {
    &::before {
      color: var(--content-emphasis);
    }
    &::after {
      color: var(--content-emphasis);
    }
  }

  .hamburger.active {
    &::before {
      transform: rotate(-45deg);
      top: 11.4px;
    }
    &::after {
      transform: rotate(45deg);
      bottom: 11.4px;
    }
  }
}

.menu {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  position: absolute;
  right: 0;
  background-color: var(--layer-1);
  box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: var(--radius-m);
  width: calc(100vw - 64px);
  z-index: 100;
  top: calc(100% + var(--spacing-16));
  padding: var(--spacing-12) var(--spacing-16);
  opacity: 0;
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  transition: all 200ms ease-in-out;

  @include breakpoint(tablet) {
    width: fit-content;
  }

  p {
    text-wrap: nowrap;
  }

  a {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-12);
    color: inherit;
    cursor: pointer;
  }
}

.menuShow {
  max-height: 150px;
  visibility: visible;
  opacity: 1;
}

.languageSwitcherWrapper {
  position: relative;

  .menu {
    width: auto;
  }

  .menuShow {
    gap: 0;
    min-width: 70px;
  }
}

.languageSwitcherIcon {
  cursor: pointer;
  display: flex;
  align-items: center;
}
