@import "/src/styles/mixins.scss";

.footer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: var(--spacing-32) var(--spacing-16);
  border-radius: var(--radius-m);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--primary-color);
  border-top: 1px solid var(--stroke-inverted);
  margin-top: var(--spacing-48);

  @include breakpoint(tablet) {
    flex-direction: row;
    align-items: flex-start;
    padding: var(--spacing-40);

    > div {
      width: 50%;
    }
  }

  &[data-appearance="dark"] {
    .company {
      p {
        color: var(--content-emphasis-inverted);
      }
    }

    .socials {
      p {
        color: var(--content-subtle-inverted);
      }
    }

    .link {
      p {
        color: var(--content-emphasis-inverted);
      }

      span {
        background-color: var(--content-subtle-inverted);
      }
    }

    .poweredBy {
      color: var(--content-subtle-inverted);
    }
  }
}

.company {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-24);

  p {
    font-size: 16px;
    @include breakpoint(tablet) {
      font-size: 18px;
    }
  }

  .logoContainer {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    margin-right: var(--spacing-12);
    border-radius: var(--radius-s);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.socials,
.links {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  margin-bottom: var(--spacing-24);

  @include breakpoint(tablet) {
    margin-bottom: var(--spacing-40);
    gap: var(--spacing-16);
  }
}

.socials {
  margin-bottom: var(--spacing-40);

  p {
    color: var(--content-subtle);
    font-size: 14px;

    @include breakpoint(tablet) {
      font-size: 16px;
    }
  }
}

.link {
  display: flex;
  justify-content: space-between;
  text-decoration: none;

  p {
    color: var(--content-emphasis);
    font-size: 16px;

    @include breakpoint(tablet) {
      font-size: 24px;
    }
  }

  span {
    width: 24px;
    height: 24px;
    border-radius: var(--radius-xs);
    background-color: var(--background-primary);
  }
}

.poweredBy {
  color: var(--content-muted);
  font-size: 14px;
  width: 100%;

  strong {
    margin-left: 3px;
  }

  @include breakpoint(tablet) {
    font-size: 16px;
  }
}
