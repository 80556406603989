@import "/src/styles/mixins.scss";

.invoiceItems {
  display: flex;
  flex-direction: column;

  h4 {
    font-weight: 500;
    padding-bottom: var(--spacing-12);
  }
}

.item {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-16) 0;
  align-items: center;

  &.vatTotal {
    padding-bottom: 0;
  }
}

.priceContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.quantityInline {
  margin-right: var(--spacing-4);
  white-space: nowrap;
}

span {
  display: inline-block;
}

.quantity {
  display: flex;
  justify-content: space-between;
  margin-top: -1rem;
}

.total {
  display: flex;
  justify-content: space-between;

  p {
    font-weight: 500;
  }

}

.divider {
  margin: 0;
  margin-top: var(--spacing-16);
  margin-left: calc(var(--spacing-16) * -1);
  width: calc(100% + (var(--spacing-16) * 2));
  box-sizing: border-box;
  height: 1px;
  border: none;
  background-color: var(--background-primary);

  @include breakpoint(tablet) {
    margin-top: var(--spacing-24);
    margin-left: calc(var(--spacing-24) * -1);
    width: calc(100% + (var(--spacing-24) * 2));
  }
}
