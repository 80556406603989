@import "./variables.css";
@import "./fonts.css";
@import "./mixins.scss";

:root {
  --background-primary: #f5f5f5;
  --primary: #002A58;

  // Content
  --content-emphasis: rgb(0, 0, 0);
  --content-subtle: rgba(0, 0, 0, 0.502);
  --content-muted: rgba(0, 0, 0, 0.302);
  --content-emphasis-inverted: rgb(255, 255, 255);
  --content-subtle-inverted: rgba(255, 255, 255, 0.502);
  --content-muted-inverted: rgba(255, 255, 255, 0.302);

  --stroke-inverted: #000;

  // Static content variables
  --static-content-emphasis: #000000;
  --static-content-subtle: #00000080;
  --static-content-muted: #0000004d;
  --static-content-emphasis-inverted: #ffffff;
  --static-content-subtle-inverted: #ffffff80;
  --static-content-muted-inverted: #ffffff4d;

  // Layers
  --layer-1: rgb(255, 255, 255);
  --layer-2: rgb(235, 235, 235);
  --layer-3: rgb(213, 213, 213);

  // Color palette
  --color-green: #29845a;
  --color-red: #e51c00;
}

body {
  --font-family: "Intrum Sans";
  margin: 0;
  padding: 0;
  font-family: var(--font-family, sans-serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  height: 100dvh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
p,
a,
label,
input {
  margin: 0;
  text-decoration: none;
  font-family: var(--font-family, sans-serif);
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  background: var(--background-primary);
}