@import "/src/styles/mixins.scss";

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-16);
  z-index: 1;
  box-sizing: border-box;

  @include breakpoint(tablet) {
    margin-bottom: var(--spacing-64);
    border-radius: var(--radius-l);
  }
}

.card {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
  margin-top: var(--spacing-16);
}

.errorMsg {
  color: var(--color-red);
}
