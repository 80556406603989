@import "/src/styles/mixins.scss";

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-16);
  z-index: 1;
  box-sizing: border-box;
  height: 100%;
  position: relative;

  @include breakpoint(tablet) {
    border-radius: var(--radius-l);
    margin-bottom: var(--spacing-64);
    height: fit-content;
    padding: 0;
  }
}

.paymentOptions {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
  margin-top: var(--spacing-16);
}

.paymentConfirmedContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-16);
  z-index: 1;
  box-sizing: border-box;
  height: 100%;
  position: relative;
}

.paymentConfirmedCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 var(--spacing-16);
  margin-bottom: var(--spacing-32);
  text-align: center;
  gap: var(--spacing-32);

  @include breakpoint(tablet) {
    padding: var(--spacing-24) var(--spacing-40);
    gap: var(--spacing-40);
  }

  > svg {
    width: 100%;
    height: 94px;
    margin-top: var(--spacing-40);
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16);

    h1 {
      font-size: 32px;
      font-weight: 400;

      @include breakpoint(tablet) {
        font-size: 40px;
      }
    }
  }

  > button {
    max-width: 294px;
    margin-bottom: var(--spacing-16);

    @include breakpoint(tablet) {
      margin-bottom: var(--spacing-40);
    }
  }
}
