.pageNotFound {
    height: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
  
    div:not(.status) {
      max-width: 60%;
      margin: 0 auto;
      position: relative;
      z-index: 1;
    }
  }
  
  .status {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 28px;
    align-items: center;
    justify-content: center;
    z-index: 0;
  
    svg {
      height: 100%;

      @media(orientation: portrait) and (max-width: 550px) {
        & {
          width: 100px;
        }
      }
  
      path {
        fill: #ebebeb6c;
      }
    }
  }
  