@import "/src/styles/mixins.scss";

.h1 {
  font-size: 48px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: -0.6px;

  @include breakpoint(tablet) {
    font-size: 56px;
    line-height: 56px;
    letter-spacing: -0.7px;
  }
}

.h2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.2px;

  @include breakpoint(tablet) {
    font-size: 24px;
    letter-spacing: -0.3px;
  }
}

.h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px;

  @include breakpoint(tablet) {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.225px;
  }
}

.h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;

  @include breakpoint(tablet) {
    font-size: 24px;
    letter-spacing: -0.3px;
  }
}

.h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -1.25%;

  @include breakpoint(tablet) {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.225px;
  }
}

.body {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px;

  @include breakpoint(tablet) {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.225px;
  }
}

.body-small, body-small--reset-line-height {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.175px;

  @include breakpoint(tablet) {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
  }
}

.body-small--reset-line-height {
  line-height: initial;
}

.detail {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.15px;

  @include breakpoint(tablet) {
    font-size: 14px;
    line-height: 20px;
  }
}
