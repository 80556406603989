.errorMessage {
    position: relative;
    max-width: 70%;
    width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    div {
        
        h1, h2, h3 {
            font-weight: 500;
            margin-top: 10px;
        }
    }
}