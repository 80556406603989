.invoiceContainer {
  padding: var(--spacing-16);
  padding-right: var(--spacing-12);
  padding-bottom: var(--spacing-20);
  background-color: var(--layer-1);
  color: var(--static-content-emphasis);
  display: grid;
  grid-gap: var(--spacing-8);

  .invoiceContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .invoiceStage {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.status {
  display: flex;
  align-items: center;
  gap: var(--spacing-8);

  p {
    font-weight: 500;
  }
}

.info {
  display: flex;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    gap: var(--spacing-6);
    text-wrap: nowrap;
  }

  svg {
    margin-left: var(--spacing-4);
  }
}

.date {
  color: var(--static-content-subtle);
  text-wrap: nowrap;
}
