@import "/src/styles/mixins.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--spacing-8);

  > p {
    font-size: 16px;

    @include breakpoint(tablet) {
      font-size: 18px;
    }
  }

  > a > p {
    color: var(--static-content-subtle);
    font-size: 16px;

    @include breakpoint(tablet) {
      font-size: 18px;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);

    > div:first-of-type {
      display: flex;
      align-items: center;
      gap: var(--spacing-12);
    }
  }
}

// Content
.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  margin: var(--spacing-32) 0;
  padding: 0;
}

// Due date
.dueDate {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--static-content-subtle);
}

.alertCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #000; // REPLACE WITH REFACTORED FRONT-END CODE
}

// Warning container
.warning {
  margin-top: var(--spacing-20);
}

// Pay button
.payCta {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
  margin-top: var(--spacing-20);

  > div {
    display: flex;
    justify-content: center;
    gap: var(--spacing-16);

    > img {
      height: 16px;

      @include breakpoint(tablet) {
        height: 20px;
      }
    }
  }

  > a > button > p {
    font-size: 16px;

    @include breakpoint(tablet) {
      font-size: 18px;
    }
  }
}

// Unpaid invoices
.moreUnpaid {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  margin-bottom: var(--spacing-16);

  > div:first-of-type {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-8);
    padding: 0 var(--spacing-8);

    p {
      color: var(--static-content-subtle);
    }
  }

  .invoices {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
  }
}

.unpaidInvoice {
  display: flex;
  align-items: center;
  gap: var(--spacing-12);
  padding: var(--spacing-12) var(--spacing-16);
  border-radius: var(--radius-s);
  border: 1px solid var(--background-primary);
  cursor: pointer;
  background: transparent;
  outline: none;
  width: 100%;
  text-align: left;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: var(--spacing-8);
    text-wrap: nowrap;
  }

  h4 {
    font-size: 16px;

    @include breakpoint(tablet) {
      font-size: 18px;
    }
  }

  .dueDate {
    color: var(--static-content-subtle);
  }
}
