@import "/src/styles/mixins.scss";

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--background-primary);
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-16);
  z-index: 1;
  box-sizing: border-box;

  @include breakpoint(tablet) {
    background-color: transparent;
    padding: 0;
    margin-bottom: var(--spacing-64);
    border-radius: var(--radius-l);
  }
}

.invoiceDetails {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);

  @include breakpoint(tablet) {
    gap: var(--spacing-20);
  }
}

.invoiceNumber {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-8);

  &:last-of-type {
    margin-bottom: 0;
  }

  > div {
    display: flex;
    gap: var(--spacing-12);
  }

  .copyBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--radius-xs);
    background: var(--background-primary);

    & > [class*="Tooltip"] {
      & > div {
        padding: 5px;
      }
    }
  }
}

.ctaBtns {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);

  @include breakpoint(tablet) {
    flex-direction: row;
    gap: var(--spacing-20);
  }

  > a {
    width: 100%;
  }

  > button > p {
    font-size: 16px;
  }
}
