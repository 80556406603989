@import "/src/styles/mixins.scss";

.card {
  transition: border 200ms ease-in-out;
  border-radius: var(--radius-m);
  background: var(--layer-1);
  padding: var(--spacing-16);
  border: 2px solid transparent;

  @include breakpoint(tablet) {
    padding: var(--spacing-24);
  }
}
