.button {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: var(--spacing-12) var(--spacing-16);
  border-radius: var(--radius-s);
  border: 1px solid var(--stroke-inverted);
  width: 100%;
}

.buttonClear {
  padding: 0;
  border: none;
  width: fit-content;
  height: fit-content;
}

.buttonFill {
  width: 100%;
}

.buttonWithIcon {
  justify-content: space-between;
}

.buttonLeftText {
  justify-content: flex-start;
}

.buttonPrimary {
  background: var(--primary-color);

  p {
    color: var(--content-emphasis-inverted);
  }
}

.buttonTertiary {
  background: var(--layer-2);
  border-color: var(--layer-2);

  p {
    font-weight: 400;
  }
}

.buttonSuccess {
  background-color: #ecf8f3;
  color: var(--green-colour);
  font-weight: normal;
  border: none;

  * {
    font-weight: inherit !important;
  }
}

.buttonDisabled {
  background-color: #d4d4d4;
  color: var(--content-subtle);
  font-weight: normal;
  border: none;
  cursor: not-allowed;
}
