.pill {
  padding: var(--spacing-2) var(--spacing-6);
  border-radius: var(--radius-xs);
  background-color: var(--background-primary);
  height: fit-content;
  width: fit-content;

  > p {
    text-wrap: nowrap;
    font-weight: 500;
    color: var(--static-content-subtle);
    text-transform: capitalize;
  }
}

.pillError, .pillSuccess {
  > p {
    color: var(--static-content-emphasis-inverted);
  }
}

.pillError {
  background: var(--color-red);
}

.pillSuccess {
  background: var(--color-green);
}

.pillNotice {
  background: #F9DFFD;

  > p {
    color: #9B00B2;
  }
}

.pillInfo {
  background: #CCE4FF;

  > p {
    color: #0057B7;
  }
}

.pillDanger {
  background: #FFDCDA;

  > p {
    color: #B21600;
  }
}


.pillWarning {
  background: #F9A000;

  > p {
    color: #FFFFFF;
  }
}

