.tooltipContent {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  animation: showTooltip 0.5s ease-in-out forwards;
  z-index: 9999;
  border-radius: 4px;
  background-color: var(--primary-color);
  color: var(--content-emphasis-inverted);
  border: 1px solid var(--stroke-inverted);
}

@keyframes showTooltip {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
