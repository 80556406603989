.main {
  display: flex;
  width: 100%;
  flex: 1 0 auto;
  margin: 0 auto;
  transform: translateY(-50px);

  &.noTranslate {
    transform: none;
  }

  @media (max-width: 1024px) {
    & {
      width: 90%;
    }
  }
}
