@import "/src/styles/mixins.scss";

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-16);
  padding-top: 0;
  z-index: 1;
  box-sizing: border-box;

  @include breakpoint(tablet) {
    margin-bottom: var(--spacing-64);
    border-radius: var(--radius-l);
  }

  > header {
    margin-bottom: var(--spacing-16);

    @include breakpoint(tablet) {
      margin-bottom: var(--spacing-24);
    }
  }

  > section {
    margin-bottom: var(--spacing-32);

    @include breakpoint(tablet) {
      margin-bottom: var(--spacing-40);
    }

    &:first-of-type {
      margin-top: var(--spacing-16);
      @include breakpoint(tablet) {
        margin-top: var(--spacing-24);
      }
    }

    &:last-of-type {
      margin-bottom: var(--spacing-40);

      @include breakpoint(tablet) {
        margin-bottom: 0;
      }
    }

    > button {
      width: 50%;
      margin: 0 auto;
      margin-top: var(--spacing-16);

      @include breakpoint(tablet) {
        width: 100%;
      }
    }
  }
}

.oneUnpaidInvoice {
  margin-top: var(--spacing-24);
}

.invoices {
  max-height: 720px;
  overflow: auto;

  > a {
    border-bottom: 1px solid var(--background-primary);
  }

  > a:first-of-type {
    border-top-left-radius: var(--radius-m);
    border-top-right-radius: var(--radius-m);
  }
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  padding: 7px;
}
