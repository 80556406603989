@import "/src/styles/mixins.scss";

.paymentCard {
  width: 100%;
  text-align: left;
  padding: var(--spacing-16);

  @include breakpoint(tablet) {
    padding: var(--spacing-24);
    padding-bottom: var(--spacing-16);
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-4);

  > div:first-of-type {
    display: flex;
    gap: var(--spacing-8);
  }

  img {
    height: 16px;
  }
}

.paymentForm {
  padding: var(--spacing-16);
  padding-top: 0;
  cursor: default;

  @include breakpoint(tablet) {
    padding: var(--spacing-24);
    padding-top: 0;
  }
}

// Swish payment form
.paymentSwish {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 var(--spacing-8);

  > button {
    margin-top: var(--spacing-16);
    margin-bottom: var(--spacing-24);
  }

  .spinner {
    margin-top: var(--spacing-16);
    margin-bottom: var(--spacing-24);
  }
}

.imgContainer {
  margin-top: var(--spacing-40);
  margin-bottom: var(--spacing-64);

  > img {
    width: 250px;
    height: 250px;
  }
}

// Bank transfer payment form
.bankTransferWrapper {
  padding: var(--spacing-24);
  padding-top: 0 !important;
  display: grid;
  grid-gap: var(--spacing-16);

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .copy {
    display: flex;
    align-items: center;
    gap: var(--spacing-12);
  }
}
