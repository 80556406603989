@import "/src/styles/mixins.scss";

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-16);
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: var(--spacing-16);
  padding-top: 0;
  border-radius: var(--radius-l);
  z-index: 1;
  position: relative;
  box-sizing: border-box;

  @include breakpoint(tablet) {
    margin-top: 0;
    padding: 0;
  }

  > a > section > button > p {
    font-size: 16px;

    @include breakpoint(tablet) {
      font-size: 18px;
    }
  }
}
