.wrapper {
  padding: var(--spacing-16) var(--spacing-12);
  padding-right: var(--spacing-4);
  background-color: var(--layer-1);
  color: var(--static-content-emphasis);
  border-bottom: 1px solid var(--background-primary);
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

.flex {
  display: flex;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
