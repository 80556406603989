@import "/src/styles/mixins.scss";

.togglePrimary {
  input[type="checkbox"] {
    &:checked + span {
      background: var(--color-green);
    }
  }
}

.toggleContainer {
  display: flex;
  align-items: center;
  gap: 8px;

  .toggleDefault {
    width: 40px;
    height: 24px;

    &:after {
      top: 2px;
      left: 2px;
      width: 20px;
      height: 20px;
    }

    @include breakpoint(tablet) {
      width: 56px;
      height: 32px;

      &:after {
        top: 3px;
        left: 2px;
        width: 26px;
        height: 26px;
      }
    }
  }

  input[type="checkbox"] {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    z-index: 1;

    &:checked + span:after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }

  span {
    display: block;
    border-radius: 18px;
    position: relative;
    transition: 0.3s;
    background: var(--static-content-muted);

    &:after {
      content: "";
      position: absolute;
      background: white;
      border-radius: 50%;
      transition: 0.3s;
    }
  }
}
