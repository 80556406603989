@import "/src/styles/mixins.scss";

.tooltipContainer {
  position: relative;

  > div {
    display: flex;
  }
}

.tooltip {
  position: absolute;
  top: var(--spacing-24);
  opacity: 0;
  transition:
    opacity 200ms ease-in-out,
    z-index 200ms ease-in-out;
  padding: var(--spacing-4) var(--spacing-8);
  margin: var(--spacing-4) var(--spacing-8);
  border-radius: var(--radius-xs);
  z-index: -1;
  min-width: 320px;
  background-color: var(--primary-color);
  color: var(--content-emphasis-inverted);
  border: 1px solid var(--stroke-inverted);

  p {
    text-wrap: wrap;
    width: fit-content;
  }

  @include breakpoint(tablet) {
    margin: 0;
  }
}

.tooltip.tooltipAutoMinWidth {
  min-width: auto;
}

.tooltip.tooltipTop {
  top: -40%;
  left: 50% !important;
  right: unset;
  transform: translateY(-100%) translateX(-50%);

  @media (orientation: portrait) {
    & {
      left: 0% !important;
    }
  }
}

.tooltipShow {
  opacity: 1;
  z-index: 100;
}
